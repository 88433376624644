import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { AuthContext } from '@/contexts/auth-context';

function RootPage() {
  const router = useRouter();
  const { isUserLoggedIn, loggedInCompanyId, externalAuthProvider } = useContext(AuthContext);

  const newRoute = isUserLoggedIn ? '/merchants/overview' : '/login';

  if (externalAuthProvider?.isWidgetLogin && isUserLoggedIn && loggedInCompanyId) {
    router.push(`/${loggedInCompanyId}/general`);
  } else {
    router.push(newRoute);
  }
  return <></>;
}

export default RootPage;
